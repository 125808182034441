import React, {
  Dispatch,
  RefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { getMission, updateMission } from '../../api'
import {
  MissionTypeAPI,
  MissionStatusType,
  MissionUpdateTypeAPI,
  QuestionTypeAPI
} from '../../api/types'
import { Loading } from '../../components/shared/Loading'
import { WizardConsts } from '../../Constants'
import { Button } from '../../elements/Button'
import { getPaths } from '../../routing/routes'

import styles from './Wizard.module.scss'


const { DEFAULT_NEW_QUESTION } = WizardConsts

export type StepProps = {
  mission: MissionTypeAPI
}

type StepFunctions = {
  updateData: (newStatus: MissionStatusType) => MissionUpdateTypeAPI
  getWarnings: () => string[]
}

export type WizardContextType = {
  mission: MissionTypeAPI
  setStep: Dispatch<SetStateAction<number>>
  stepRef: RefObject<StepFunctions>
}

type WizardProps = {
  missionId: number
}


export const Wizard = ({ missionId }: WizardProps) => {
  const i18n = useTranslation()
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)
  const [step, setStep] = useState(0)
  const [mission, setMission] = useState<MissionTypeAPI>()
  const [warnings, setWarnings] = useState<string[]>([])

  const stepRef = useRef<StepFunctions>(null)


  const goBack = useCallback(() => {
    navigate(getPaths.brands.missions.ongoing)
  }, [navigate])


  const goForth = useCallback(() => {
    navigate(getPaths.brands.mission.details(missionId))
  }, [navigate, missionId])


  // Download images for questions received from the BE, because they have a timeout to be used
  const downloadQuestionImages = useCallback(async (missionToUpdate: MissionTypeAPI) => {
    const questionsUpdated = Array(missionToUpdate.questions?.length).fill(false)
    missionToUpdate.questions?.forEach(async (question, i) => {
      if (typeof question.image !== 'string') {
        questionsUpdated[i] = true
        if (questionsUpdated.every(q => q)) setMission(missionToUpdate) // all questions updated, so we update mission
        return
      }

      try {
        const response = await fetch(question.image)
        if (!response.ok) throw new Error

        const blob = await response.blob()
        // eslint-disable-next-line i18next/no-literal-string
        const file = new File([blob], `question${i}mission${missionId}image`, { type: blob.type })
        question.image = file
        questionsUpdated[i] = true
        if (questionsUpdated.every(q => q)) setMission(missionToUpdate) // all questions updated, so we update mission
      } catch (error) {
        question.image = null
        toast.error(i18n.t('Question:ImageForQuestionNotFound', { n: question.order + 1 }))
        toast.error(`Error: ${error}`)
        console.log(`Error downloading image for question ${question.order + 1}: ${error}`)
        console.log(`Image URL: ${question.image}`)
        console.log(`Error: ${error}`)
        questionsUpdated[i] = true
        if (questionsUpdated.every(q => q)) setMission(missionToUpdate) // all questions updated, so we update mission
      }
    })
  }, [i18n, missionId])


  const getMissionData = useCallback((isFirst: boolean) => {
    getMission(missionId).then(response => {
      if (!response.success) {
        toast.error(i18n.t('ToastError:MissionNotFound'), {autoClose: 2600, pauseOnHover: false})
        setTimeout(() => {
          goBack()
        }, 3000);
        return
      }

      const mission = response.data

      const missionStep =
        mission.status === `step_1` ? 1
          : mission.status === `step_2` ? 2
            : mission.status === `step_3` ? 3
              : mission.status === `step_4` ? 4
                : 0

      if (isFirst) {
        if (missionStep === 0) {
          goForth()
        } else {
          if (missionStep !== step) {
            navigate(getPaths.brands.wizard[`step_${missionStep}`](missionId))
          }
        }
      }


      if (mission.questions?.length === 0) {
        mission.questions = [DEFAULT_NEW_QUESTION() as QuestionTypeAPI]
      }

      if (mission.statusChanged) {
        toast.error(i18n.t('ToastError:ChangedMissionStatus'))
      }

      if (missionStep === 1) downloadQuestionImages(mission)

      setMission(mission)
    })
  }, [goBack, goForth, i18n, step, navigate, missionId, downloadQuestionImages])


  useEffect(() => {
    getMissionData(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const handlePrevious = useCallback(() => {
    if (!stepRef.current) return
    if (!mission) return

    setLoading(true)
    setWarnings([])
    // eslint-disable-next-line i18next/no-literal-string
    const newStatus: MissionStatusType = step === 4 ? 'step_3' : step === 3 ? 'step_2' : 'step_1'
    const dataToUpdate: MissionUpdateTypeAPI = stepRef.current.updateData(newStatus)

    updateMission(mission.id, dataToUpdate).then(response => {
      if (!response.success) {
        toast.error(i18n.t('ToastError:ErrorUpdatingMission'))
        setLoading(false)
        return
      }

      setMission(undefined)
      getMissionData(false)

      if (step === 1) {
        goBack()
      } else {
        navigate(getPaths.brands.wizard[newStatus](mission.id))
      }

      setLoading(false)
    })
  }, [getMissionData, goBack, i18n, mission, navigate, step])


  const handleNext = useCallback(() => {
    if (!stepRef.current) return
    if (!mission) return

    setLoading(true)
    // eslint-disable-next-line i18next/no-literal-string
    const newStatus: MissionStatusType = step === 1 ? 'step_2' : step === 2 ? 'step_3' : step === 3 ? 'step_4' : 'created'
    const newWarnings: string[] = stepRef.current.getWarnings()
    if (newWarnings.length > 0) {
      toast.error(i18n.t('ToastError:InformationMissing'))
      setWarnings(newWarnings)
      window.scrollTo({top: 0, behavior: 'smooth'})
      setLoading(false)
      return
    }
    setWarnings([])

    const dataToUpdate: MissionUpdateTypeAPI = stepRef.current.updateData(newStatus)
    updateMission(mission.id, dataToUpdate).then(response => {
      if (!response.success) {
        toast.error(i18n.t('ToastError:ErrorUpdatingMission'))
        setLoading(false)
        return
      }

      setMission(undefined)
      getMissionData(false)

      if (newStatus === 'created') {
        goForth()
      } else {
        navigate(getPaths.brands.wizard[newStatus](mission.id))
      }
      setLoading(false)
    })
  }, [getMissionData, goForth, i18n, mission, navigate, step])


  return (
    <div>
      <div className={styles.wizard}> 
        <div className={styles.wizardHeader}>
          <h1>
            {i18n.t('MissionWizard:CreateNewMission')} { ((step === 2 || step === 3) && mission) && `(${mission.title})` }
          </h1>
          <div className={styles.stepsCounter}>
            {step > 0 && (
              Array(4).fill(null).map((_, i) => (
                <div
                  key={`stepCounter_${i+1}`}
                  className={`${styles.stepsCounterElement} ${step === i+1 && styles.active}`}
                >
                  {step === i+1 ? `${i18n.t('MissionWizard:Step')} ${i+1}` : i+1}
                </div>
              ))
            )}
          </div>
        </div>
        {warnings.length > 0 && (
          <div className={styles.warnings}>
            <h2 className={styles.warningText}>
              {i18n.t('Common:Warnings')}
            </h2>
            <div>
              {warnings.map((w, i) => (
                <h4
                  key={`warning_${i}`}
                  className={styles.warningText}
                >
                  {`${(i+1)}) ${w}`}
                </h4>
              ))}
            </div>
          </div>
        )}
        {!mission ? (
          <Loading />
        ) : (
          <>
            <Outlet context={{ mission, setStep, stepRef } satisfies WizardContextType}/>
            <div className={styles.wizardFooter}>
              <Button
                title={step === 1 ? i18n.t('Common:Cancel') : i18n.t('Common:Previous')}
                handler={handlePrevious}
                variant='neutral'
                loading={loading}
                fullWidth
                fullHeight
              />
              <Button
                title={step === 4 ? i18n.t('MissionWizard:CreateMission') : i18n.t('Common:Next')}
                handler={handleNext}
                loading={loading}
                fullWidth
                fullHeight
              />
            </div>
          </>
        )}
      </div>
    </div>
  )
}
